<template>
    <footer class="bg-slate-100 text-center items-center grid grid-cols-3">
        <div class="flex justify-center space-x-5 py-4">
            <a
                href="https://www.facebook.com/levi.cook.927?mibextid=ZbWKwL"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                    src="https://img.icons8.com/fluent/30/000000/facebook-new.png"
                    class="w-8"
                />
            </a>
            <a
                href="https://www.instagram.com/levicook01?igsh=cmpqNTF6MXluenYw"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                    src="https://img.icons8.com/fluent/30/000000/instagram-new.png"
                    class="w-9"
                />
            </a>
            <a href="mailto:contact@levicook.ca"
                ><img
                    src="https://cdn-icons-png.freepik.com/256/14026/14026755.png?semt=ais_hybrid"
                    class="w-8"
                />
            </a>

            <a href="tel:2502210204"
                ><img
                    src="https://static-00.iconduck.com/assets.00/phone-icon-2048x2048-nottqc8j.png"
                    class="w-8"
                />
            </a>
        </div>

        <div>
            <div class="grid grid-rows-2">
                <div>
                    &copy; {{ new Date().getFullYear() }} —
                    <strong>Levi Cook</strong>
                </div>
                <div>
                    <a
                        href="https://www.saltspringtourism.com/"
                        class="text-blue-500 hover:text-blue-700"
                        >Salt Spring Tourism Board</a
                    >
                </div>
            </div>
        </div>
        <div>
            <a target="_blank" href="https://www.macrealty.com/">
                <img
                    src="@/assets/MacdonaldRealtyLogo.png"
                    alt="Macdonald Realty Logo"
                    class="aspect-auto h-48 mb-1 drop-shadow-md"
                />
            </a>
        </div>
    </footer>
</template>

<script setup></script>
