<template>
    <section id="about">
        <!-- ====== About Section Start -->
        <section
            class="overflow-hidden pt-20 pb-12 lg:pt-[120px] lg:pb-[90px] bg-white dark:bg-dark"
        >
            <div class="container mx-auto">
                <div class="flex flex-wrap items-center justify-between -mx-4">
                    <div class="w-full px-4 lg:w-6/12">
                        <div class="flex items-center -mx-3 sm:-mx-4">
                            <div class="w-full px-3 sm:px-4 xl:w-1/2">
                                <div class="py-3 sm:py-4">
                                    <img
                                        src="https://images.unsplash.com/photo-1535639657766-1267c47e191c?q=80&w=2243&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                        alt=""
                                        class="w-full rounded-2xl"
                                    />
                                </div>
                                <div class="py-3 sm:py-4">
                                    <img
                                        src="https://images.unsplash.com/photo-1562457346-5702b6ec389d?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                        alt=""
                                        class="w-full rounded-2xl"
                                    />
                                </div>
                            </div>
                            <div class="w-full px-3 sm:px-4 xl:w-1/2">
                                <div class="relative z-10 my-4">
                                    <img src="https://pub-f763f1bed6dc4a038e158fbce783f583.r2.dev/Headshots_Edited_4_of_25.jpg"
                                        alt="" class="w-full rounded-2xl" />
                                    <span class="absolute -right-7 -bottom-7 z-[-1]">
                                        <svg width="134" height="106" viewBox="0 0 134 106" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="1.66667" cy="104" r="1.66667"
                                                transform="rotate(-90 1.66667 104)" fill="#3056D3" />
                                            <circle cx="16.3333" cy="104" r="1.66667"
                                                transform="rotate(-90 16.3333 104)" fill="#3056D3" />
                                            <circle cx="31" cy="104" r="1.66667" transform="rotate(-90 31 104)"
                                                fill="#3056D3" />
                                            <circle cx="45.6667" cy="104" r="1.66667"
                                                transform="rotate(-90 45.6667 104)" fill="#3056D3" />
                                            <circle cx="60.3334" cy="104" r="1.66667"
                                                transform="rotate(-90 60.3334 104)" fill="#3056D3" />
                                            <circle cx="88.6667" cy="104" r="1.66667"
                                                transform="rotate(-90 88.6667 104)" fill="#3056D3" />
                                            <circle cx="117.667" cy="104" r="1.66667"
                                                transform="rotate(-90 117.667 104)" fill="#3056D3" />
                                            <circle cx="74.6667" cy="104" r="1.66667"
                                                transform="rotate(-90 74.6667 104)" fill="#3056D3" />
                                            <circle cx="103" cy="104" r="1.66667" transform="rotate(-90 103 104)"
                                                fill="#3056D3" />
                                            <circle cx="132" cy="104" r="1.66667" transform="rotate(-90 132 104)"
                                                fill="#3056D3" />
                                            <circle cx="1.66667" cy="89.3333" r="1.66667"
                                                transform="rotate(-90 1.66667 89.3333)" fill="#3056D3" />
                                            <circle cx="16.3333" cy="89.3333" r="1.66667"
                                                transform="rotate(-90 16.3333 89.3333)" fill="#3056D3" />
                                            <circle cx="31" cy="89.3333" r="1.66667" transform="rotate(-90 31 89.3333)"
                                                fill="#3056D3" />
                                            <circle cx="45.6667" cy="89.3333" r="1.66667"
                                                transform="rotate(-90 45.6667 89.3333)" fill="#3056D3" />
                                            <circle cx="60.3333" cy="89.3338" r="1.66667"
                                                transform="rotate(-90 60.3333 89.3338)" fill="#3056D3" />
                                            <circle cx="88.6667" cy="89.3338" r="1.66667"
                                                transform="rotate(-90 88.6667 89.3338)" fill="#3056D3" />
                                            <circle cx="117.667" cy="89.3338" r="1.66667"
                                                transform="rotate(-90 117.667 89.3338)" fill="#3056D3" />
                                            <circle cx="74.6667" cy="89.3338" r="1.66667"
                                                transform="rotate(-90 74.6667 89.3338)" fill="#3056D3" />
                                            <circle cx="103" cy="89.3338" r="1.66667"
                                                transform="rotate(-90 103 89.3338)" fill="#3056D3" />
                                            <circle cx="132" cy="89.3338" r="1.66667"
                                                transform="rotate(-90 132 89.3338)" fill="#3056D3" />
                                            <circle cx="1.66667" cy="74.6673" r="1.66667"
                                                transform="rotate(-90 1.66667 74.6673)" fill="#3056D3" />
                                            <circle cx="1.66667" cy="31.0003" r="1.66667"
                                                transform="rotate(-90 1.66667 31.0003)" fill="#3056D3" />
                                            <circle cx="16.3333" cy="74.6668" r="1.66667"
                                                transform="rotate(-90 16.3333 74.6668)" fill="#3056D3" />
                                            <circle cx="16.3333" cy="31.0003" r="1.66667"
                                                transform="rotate(-90 16.3333 31.0003)" fill="#3056D3" />
                                            <circle cx="31" cy="74.6668" r="1.66667" transform="rotate(-90 31 74.6668)"
                                                fill="#3056D3" />
                                            <circle cx="31" cy="31.0003" r="1.66667" transform="rotate(-90 31 31.0003)"
                                                fill="#3056D3" />
                                            <circle cx="45.6667" cy="74.6668" r="1.66667"
                                                transform="rotate(-90 45.6667 74.6668)" fill="#3056D3" />
                                            <circle cx="45.6667" cy="31.0003" r="1.66667"
                                                transform="rotate(-90 45.6667 31.0003)" fill="#3056D3" />
                                            <circle cx="60.3333" cy="74.6668" r="1.66667"
                                                transform="rotate(-90 60.3333 74.6668)" fill="#3056D3" />
                                            <circle cx="60.3333" cy="30.9998" r="1.66667"
                                                transform="rotate(-90 60.3333 30.9998)" fill="#3056D3" />
                                            <circle cx="88.6667" cy="74.6668" r="1.66667"
                                                transform="rotate(-90 88.6667 74.6668)" fill="#3056D3" />
                                            <circle cx="88.6667" cy="30.9998" r="1.66667"
                                                transform="rotate(-90 88.6667 30.9998)" fill="#3056D3" />
                                            <circle cx="117.667" cy="74.6668" r="1.66667"
                                                transform="rotate(-90 117.667 74.6668)" fill="#3056D3" />
                                            <circle cx="117.667" cy="30.9998" r="1.66667"
                                                transform="rotate(-90 117.667 30.9998)" fill="#3056D3" />
                                            <circle cx="74.6667" cy="74.6668" r="1.66667"
                                                transform="rotate(-90 74.6667 74.6668)" fill="#3056D3" />
                                            <circle cx="74.6667" cy="30.9998" r="1.66667"
                                                transform="rotate(-90 74.6667 30.9998)" fill="#3056D3" />
                                            <circle cx="103" cy="74.6668" r="1.66667"
                                                transform="rotate(-90 103 74.6668)" fill="#3056D3" />
                                            <circle cx="103" cy="30.9998" r="1.66667"
                                                transform="rotate(-90 103 30.9998)" fill="#3056D3" />
                                            <circle cx="132" cy="74.6668" r="1.66667"
                                                transform="rotate(-90 132 74.6668)" fill="#3056D3" />
                                            <circle cx="132" cy="30.9998" r="1.66667"
                                                transform="rotate(-90 132 30.9998)" fill="#3056D3" />
                                            <circle cx="1.66667" cy="60.0003" r="1.66667"
                                                transform="rotate(-90 1.66667 60.0003)" fill="#3056D3" />
                                            <circle cx="1.66667" cy="16.3333" r="1.66667"
                                                transform="rotate(-90 1.66667 16.3333)" fill="#3056D3" />
                                            <circle cx="16.3333" cy="60.0003" r="1.66667"
                                                transform="rotate(-90 16.3333 60.0003)" fill="#3056D3" />
                                            <circle cx="16.3333" cy="16.3333" r="1.66667"
                                                transform="rotate(-90 16.3333 16.3333)" fill="#3056D3" />
                                            <circle cx="31" cy="60.0003" r="1.66667" transform="rotate(-90 31 60.0003)"
                                                fill="#3056D3" />
                                            <circle cx="31" cy="16.3333" r="1.66667" transform="rotate(-90 31 16.3333)"
                                                fill="#3056D3" />
                                            <circle cx="45.6667" cy="60.0003" r="1.66667"
                                                transform="rotate(-90 45.6667 60.0003)" fill="#3056D3" />
                                            <circle cx="45.6667" cy="16.3333" r="1.66667"
                                                transform="rotate(-90 45.6667 16.3333)" fill="#3056D3" />
                                            <circle cx="60.3333" cy="60.0003" r="1.66667"
                                                transform="rotate(-90 60.3333 60.0003)" fill="#3056D3" />
                                            <circle cx="60.3333" cy="16.3333" r="1.66667"
                                                transform="rotate(-90 60.3333 16.3333)" fill="#3056D3" />
                                            <circle cx="88.6667" cy="60.0003" r="1.66667"
                                                transform="rotate(-90 88.6667 60.0003)" fill="#3056D3" />
                                            <circle cx="88.6667" cy="16.3333" r="1.66667"
                                                transform="rotate(-90 88.6667 16.3333)" fill="#3056D3" />
                                            <circle cx="117.667" cy="60.0003" r="1.66667"
                                                transform="rotate(-90 117.667 60.0003)" fill="#3056D3" />
                                            <circle cx="117.667" cy="16.3333" r="1.66667"
                                                transform="rotate(-90 117.667 16.3333)" fill="#3056D3" />
                                            <circle cx="74.6667" cy="60.0003" r="1.66667"
                                                transform="rotate(-90 74.6667 60.0003)" fill="#3056D3" />
                                            <circle cx="74.6667" cy="16.3333" r="1.66667"
                                                transform="rotate(-90 74.6667 16.3333)" fill="#3056D3" />
                                            <circle cx="103" cy="60.0003" r="1.66667"
                                                transform="rotate(-90 103 60.0003)" fill="#3056D3" />
                                            <circle cx="103" cy="16.3333" r="1.66667"
                                                transform="rotate(-90 103 16.3333)" fill="#3056D3" />
                                            <circle cx="132" cy="60.0003" r="1.66667"
                                                transform="rotate(-90 132 60.0003)" fill="#3056D3" />
                                            <circle cx="132" cy="16.3333" r="1.66667"
                                                transform="rotate(-90 132 16.3333)" fill="#3056D3" />
                                            <circle cx="1.66667" cy="45.3333" r="1.66667"
                                                transform="rotate(-90 1.66667 45.3333)" fill="#3056D3" />
                                            <circle cx="1.66667" cy="1.66683" r="1.66667"
                                                transform="rotate(-90 1.66667 1.66683)" fill="#3056D3" />
                                            <circle cx="16.3333" cy="45.3333" r="1.66667"
                                                transform="rotate(-90 16.3333 45.3333)" fill="#3056D3" />
                                            <circle cx="16.3333" cy="1.66683" r="1.66667"
                                                transform="rotate(-90 16.3333 1.66683)" fill="#3056D3" />
                                            <circle cx="31" cy="45.3333" r="1.66667" transform="rotate(-90 31 45.3333)"
                                                fill="#3056D3" />
                                            <circle cx="31" cy="1.66683" r="1.66667" transform="rotate(-90 31 1.66683)"
                                                fill="#3056D3" />
                                            <circle cx="45.6667" cy="45.3333" r="1.66667"
                                                transform="rotate(-90 45.6667 45.3333)" fill="#3056D3" />
                                            <circle cx="45.6667" cy="1.66683" r="1.66667"
                                                transform="rotate(-90 45.6667 1.66683)" fill="#3056D3" />
                                            <circle cx="60.3333" cy="45.3338" r="1.66667"
                                                transform="rotate(-90 60.3333 45.3338)" fill="#3056D3" />
                                            <circle cx="60.3333" cy="1.66683" r="1.66667"
                                                transform="rotate(-90 60.3333 1.66683)" fill="#3056D3" />
                                            <circle cx="88.6667" cy="45.3338" r="1.66667"
                                                transform="rotate(-90 88.6667 45.3338)" fill="#3056D3" />
                                            <circle cx="88.6667" cy="1.66683" r="1.66667"
                                                transform="rotate(-90 88.6667 1.66683)" fill="#3056D3" />
                                            <circle cx="117.667" cy="45.3338" r="1.66667"
                                                transform="rotate(-90 117.667 45.3338)" fill="#3056D3" />
                                            <circle cx="117.667" cy="1.66683" r="1.66667"
                                                transform="rotate(-90 117.667 1.66683)" fill="#3056D3" />
                                            <circle cx="74.6667" cy="45.3338" r="1.66667"
                                                transform="rotate(-90 74.6667 45.3338)" fill="#3056D3" />
                                            <circle cx="74.6667" cy="1.66683" r="1.66667"
                                                transform="rotate(-90 74.6667 1.66683)" fill="#3056D3" />
                                            <circle cx="103" cy="45.3338" r="1.66667"
                                                transform="rotate(-90 103 45.3338)" fill="#3056D3" />
                                            <circle cx="103" cy="1.66683" r="1.66667"
                                                transform="rotate(-90 103 1.66683)" fill="#3056D3" />
                                            <circle cx="132" cy="45.3338" r="1.66667"
                                                transform="rotate(-90 132 45.3338)" fill="#3056D3" />
                                            <circle cx="132" cy="1.66683" r="1.66667"
                                                transform="rotate(-90 132 1.66683)" fill="#3056D3" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-full px-4 lg:w-1/2 xl:w-5/12">
                        <div class="mt-10 lg:mt-0">
                            <span
                                class="block mb-4 text-3xl font-bold text-primary"
                            >
                                Why Choose Me
                            </span>
                            <p
                                class="mb-5 text-base text-body-color dark:text-dark-6"
                            >
                                As a life-long Salt Spring local, I am your 
                                ticket to unlocking the hidden treasures
                                treasures of Salt Spring Island's real estate
                                market. As a dedicated and ambitious young
                                professional, I bring a fresh perspective
                                and unwavering enthusiasm to the table. With a
                                deep-rooted passion for the island's unique
                                charm and a keen eye for detail, I aim to be
                                your trusted guide in finding the perfect home
                                tailored to your needs. My commitment to
                                excellence, coupled with local expertise,
                                ensures a seamless and stress-free experience
                                from start to finish. Whether you're searching
                                for a cozy retreat or a luxurious waterfront
                                property, my personalized approach and
                                unparalleled dedication make me the ideal
                                partner for your real estate journey on Salt
                                Spring Island.
                            </p>
                            <h2 class="mb-5 text-black text-2xl font-semibold">
                                Begin your island life.
                            </h2>

                            <a
                                @click="scrollToSection('contact')"
                                class="inline-flex cursor-pointer items-center bg-[#022e98] justify-center py-3 text-base font-medium text-center text-white border border-transparent rounded-md px-7 bg-primary hover:bg-opacity-90"
                            >
                                Get Started
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- ====== About Section End -->
    </section>
</template>

<script>
export default {
    methods: {
        scrollToSection(sectionId) {
            const section = document.getElementById(sectionId);

            console.log(section);

            if (section) {
                section.scrollIntoView({ behavior: "smooth" });
            }
        },
    },
};
</script>
