export default {
    methods: {
        scrollToSection(sectionId) {
            const section = document.getElementById(sectionId);

            console.log(section);

            if (section) {
                window.scrollTo({
                    top: section.offsetTop,
                    behavior: "smooth",
                });
            }
        },
    },
};
