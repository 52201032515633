<template>
    <nav class="bg-slate-100 fixed top-0 z-50 w-full">
        <div
            class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4"
        >
            <img
                @click="scrollToSection('home')"
                src="@/assets/logo.png"
                class="h-8 my-1 cursor-pointer"
                alt="Levi Cook"
            />
            <button
                data-collapse-toggle="navbar-default"
                type="button"
                class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                aria-controls="navbar-default"
                aria-expanded="false"
            >
                <span class="sr-only">Open main menu</span>
                <svg
                    class="w-5 h-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 17 14"
                >
                    <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M1 1h15M1 7h15M1 13h15"
                    />
                </svg>
            </button>
            <div class="hidden w-full md:block md:w-auto" id="navbar-default">
                <ul
                    class="font-medium flex flex-col p-4 md:p-0 mt-4 border md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0"
                >
                    <li
                        v-for="(section, index) in sections"
                        :key="index"
                        :class="{ active: index === activeSection }"
                        class="cursor-pointer hover:text-[#002894] transition duration-250 group"
                    >
                        <a @click="scrollToSection(section.id)">
                            <i
                                class="group-hover:scale-110 mr-0.5 transition duration-300"
                                :class="section.icon"
                            ></i>
                            {{ section.name }}
                        </a>
                    </li>

                    <li
                        class="cursor-pointer hover:text-[#002894] transition duration-250 group"
                    >
                        <a href="tel:2502210204">
                            <i
                                class="fa-solid fa-phone group-hover:scale-110 transition duration-300"
                            ></i>
                            (250) 221-0204
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import scroll from "@/assets/helpers/scroll.js";

export default {
    data() {
        return {
            sections: [
                { name: "About", icon: "fa-solid fa-user-tie", id: "about" },
                { name: "Contact", icon: "fa-solid fa-message", id: "contact" },
            ],
            activeSection: 0,
        };
    },
    methods: {
        scrollToSection(sectionId) {
            const section = document.getElementById(sectionId);

            console.log(section);

            if (section) {
                section.scrollIntoView({ behavior: "smooth" });
            }
        },
    },
    mixins: [scroll],
};
</script>
