<template>
    <div
        class="w-full h-full lg:h-[85vh] bg-cover bg-center bg-no-repeat bg-fixed bg-blur-m top-0 relative"
        style="
            background-image: url('https://images.unsplash.com/photo-1531310568816-f5d0071360c4?q=80&w=2664&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
        "
        id="home"
    >
        <div
            class="absolute top-0 left-0 w-full h-full flex items-center justify-center flex-col"
        >
            <div
                class="absolute top-0 left-0 w-full h-full bg-fixed"
                style="
                    background-image: url('https://www.tysonpm.ca/img/rebel.png');
                    opacity: 0.6;
                "
            ></div>
            <div class="text-center relative text-white">
                <img
                    src="@/assets/logo-light.png"
                    alt="logo"
                    class="h-16 mb-1 drop-shadow-md"
                />
                <p class="text-xl mb-4">Experience Salt Spring Island</p>
                <!--<button class="bg-[#022e98] hover:bg-[#0e0298] text-white font-bold py-2 px-8 rounded-full">
          View Listings
        </button>-->
            </div>
        </div>
    </div>
</template>

<style scoped>
.absolute {
    position: absolute;
}

.relative {
    position: relative;
}
</style>

<script setup></script>
