// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import 'alpinejs';
import 'tailwindcss/tailwind.css';
import './assets/css/style.css'

import '@fortawesome/fontawesome-free/css/all.css';

const app = createApp(App);

app.mount('#app');
